import React, {Component} from 'react';
import { Grid, Col, Button, Alert } from 'rsuite';
import {isEmail} from 'validator';
import ReCAPTCHA from 'react-google-recaptcha';

class ContactForm extends Component{
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.sendMail = this.sendMail.bind(this);
        this.isValidForm = this.isValidForm.bind(this);
        this.showCtrlError = this.showCtrlError.bind(this);
        this.verifyCaptcha = this.verifyCaptcha.bind(this);

        this.state = {
            name:"",
            email:"",
            subject:"",
            message:"",
            valid_email:true,
            incomplete:false,
            valid_captcha:false,
        }
    }

    handleChange(e){
        this.setState({[e.target.name]: e.target.value, incomplete:false});
        if(e.target.name === 'email'){
            if(isEmail(e.target.value)){
                this.setState({valid_email:true});
            }else{
                this.setState({valid_email:false});
            }
        }

        document.getElementById(e.target.id).classList.remove('error');

    }

    sendMail(){
        var error = '';
        if(this.isValidForm()){
            if(!this.state.valid_email){                    
                document.getElementById('email').classList.add('error');
                error = 'Correo electrónico no valido'
            }else{
                if(this.state.valid_captcha){
                    this.setState({incomplete:false});
                    
                }else{
                    error = 'Debe verificar captcha';
                }
            }
            
        }else{
           this.setState({incomplete:true})
           error = 'Campos incompletos';
       }

        if(error === ""){
            document.getElementById('email').classList.remove('error');
            var obj = {
                name: this.state.name,
                email: this.state.email,
                subject: this.state.subject,
                message: this.state.message
            }
            
            fetch('https://www.concretosmarsan.com/sendemail.php', {
                method:'POST',
                body:JSON.stringify(obj),
                headers: new Headers({
                    'Content-Type':'application/x-www-form-urlencoded'
                }),
            }).then(res => {
                if(res.ok){
                    return res.json();
                }else{
                    res.text().then((msg)=>{
                        console.log(msg);
                    })
                }
            })
            .then(response =>{
                if(response !== undefined){
                    this.setState({
                        name:"",
                        email:"",
                        subject:"",
                        message:"",
                        valid_email:true,
                        incomplete:false,
                    });

                    if(response.error !== undefined){
                        Alert.error(response.error);
                    }
                    if(response.correct !== undefined){
                        Alert.success(response.correct);
                    }
                }
            });
        }else{
            Alert.error(error);
        }
    }

    isValidForm (){
        var ctrls = [];
        const select = document.querySelector('div.form');

        if(select !== null){
            ctrls = select.querySelectorAll('input, textarea');
        
            let isFormValid = true;
            ctrls.forEach(ctrl => {
                if(ctrl.required){
                    const isInputValid = this.showCtrlError(ctrl.id);
                    if (!isInputValid) {
                        isFormValid = false;
                    }
                }
            });
        
            return isFormValid;
        }
    }

    showCtrlError(id){
        var res = null;
        var control = document.getElementById(id);

        if(control !== null){
            if (control.value.trim() === "") {
                if(control !== null){
                    control.classList.add('error');
                }
                res = false;
            } else{
                if(control !== null){
                    if(control.required && control.className.includes('error')){
                        control.classList.remove('error');
                    }
                }
                res = true;
            }
        }

        return res;
    }

    verifyCaptcha(value){
        if(value !== "" && value !== undefined && value !== null){
            this.setState({valid_captcha: true});
        }
    }

    render(){
        return(
            <Grid fluid className="form">
                {this.state.incomplete &&
                    <Col xs={24} style={{marginBottom:10}}>
                        <span style={{color:'red', fontSize:12}}>Campos incompletos </span>
                    </Col>
                }
                <Col xs={24} style={{marginBottom:10}}>
                    <input className="form-control form-control-sm" name="name" id="name" autoComplete="off"
                        value={this.state.name} onChange={(e)=>this.handleChange(e)} placeholder="Nombre" required />
                </Col>
                <Col xs={24} style={{marginBottom:10}}>
                    {!this.state.valid_email &&
                        <span style={{color:'red', fontSize:12}}>Formato de correo electrónico no valido</span>
                    }
                    <input className="form-control form-control-sm" name="email" id="email" autoComplete="off"
                        value={this.state.email} onChange={(e)=>this.handleChange(e)} placeholder="Correo electrónico" required />
                </Col>
                <Col xs={24} style={{marginBottom:10}}>
                    <input className="form-control form-control-sm" name="subject" id="subject" autoComplete="off"
                        value={this.state.subject} onChange={(e)=>this.handleChange(e)} placeholder="Asunto" required />
                </Col>
                <Col xs={24} style={{marginBottom:10}}>
                    <textarea className="form-control form-control-sm" name="message" id="message" 
                        style={{height:"250px",width:"100%",resize:"none"}} required
                        value={this.state.message} onChange={(e)=>this.handleChange(e)} placeholder="Mensaje">
                    </textarea>
                </Col>
                <Col xs={24} style={{marginBottom:10}}>
                    <ReCAPTCHA
                        sitekey="6Ld_mDUcAAAAABZ3AemVzWKom5MA0otzbP_hxpRC"
                        onChange={this.verifyCaptcha}
                        //style={{marginTop:110}}
                    />
                </Col>
                <Col xs={24}>
                    <Button style={{background:'rgb(43, 161, 154)', color:'#FFF', fontWeight:'bold'}} 
                    onClick={()=>this.sendMail()}>Enviar</Button>
                </Col>
            </Grid>
        )
    }
}

export default ContactForm;