import React, { useState } from 'react';
import { FlexboxGrid, Col, Nav, IconButton, Icon } from 'rsuite';
import $ from 'jquery';

const Header = (props)=>{
    var width = $(window).width();
    let [showMenu, changeMenu] = useState(true);
    let [isMobile, changeScreen] = useState(false);
    
    
    if(width < 380){          
        if(!isMobile){
            changeScreen(true);
            changeMenu(false);
        }
    }
    
    
    const selectMenu = (url)=>{
        if(width < 380){
            changeMenu(false);
        }
        props.history.push(url);
    }


    return (
        <div>
            <FlexboxGrid justify="center" style={{margin:5}}>
                <Col xs={24} sm={12} md={8} lg={2}>
                    <img src="MARSAN-LOGO.png" alt="logo" style={{width:'100%'}} />
                </Col>
            </FlexboxGrid>
            <FlexboxGrid justify="center" style={{background: '#2ba19a'}}>
                <Col xs={24} smHidden mdHidden lgHidden>
                    <div style={{margin:'8px 3px', textAlign:'right'}}>
                        <IconButton icon={<Icon icon="bars" style={{fontSize:20, paddingTop:4}} />} 
                            style={{background:'transparent', color:'#FFF', border:'1px solid'}}
                            onClick={()=>changeMenu(!showMenu)}
                        />
                    </div>
                </Col>
                {showMenu &&
                    <Col xs={24} sm={20} md={12} lg={12} style={{textAlign:'center', marginBottom:-2}}>
                        <Nav appearance="subtle" className="menu-nav" vertical={isMobile}>
                            <Nav.Item onSelect={()=>selectMenu('/')}>Inicio</Nav.Item>
                            <Nav.Item onSelect={()=>selectMenu('/nosotros')}>¿Quiénes somos?</Nav.Item>
                            <Nav.Item onSelect={()=>selectMenu('/servicios')}>Servicios</Nav.Item>
                            <Nav.Item onSelect={()=>selectMenu('/contacto')}>Contacto</Nav.Item>
                        </Nav>
                    </Col>
                }
            </FlexboxGrid>
        </div>
    );
}

export default Header;