import React from 'react';
import { Grid, Col, FlexboxGrid, Divider } from 'rsuite';

const About = (props)=>{
    return(
        <Grid fluid style={{padding:0}}>
            <Col xs={24} style={{padding:0}}>
                <img src="/images/nosotros.jpeg" alt="Nosotros" style={{width:'100%'}} />
            </Col>
            <div style={{padding:10}}>
                <h1 style={{textAlign:'center', color:'#49b9e6'}}>Nosotros</h1>
            </div>
            <FlexboxGrid justify="center">
                <Col xs={24} sm={18} md={16}>
                    <p style={{textAlign:'justify'}}>
                        <strong>MARSAN</strong> nace en 2015, como una empresa 100% posotina, con el propósito de ofrecer
                        al mercado de la construcción, un conjunto de soluciones en concreto premezclado que cumpla con las
                        expectativas del cliente.<br/>
                        Somos una empresa compometida con la calidad de nuestro producto y enfocados en la eficiencia de nuestro
                        servicio.
                        Servicio superior, un liderazgo en calidad y atención al cliente, es lo que nos caracteriza.
                    </p>

                    <Divider />

                    <div>
                        <h2>Equipo de alta eficiencia</h2>
                        <p style={{textAlign:'justify'}}>
                            Una de las primcipales razones que nos permite cumplir con nuestro compromiso de entrega
                            en tiempo con nuestros clientes, es debido a que nos encontramos en una ubicacion estrategica. 
                            Grarantizando la calidad de nuestro concreto premezclado.
                        </p>    
                    </div>      
                </Col>
            </FlexboxGrid>
            
        </Grid>
    )
}

export default About;