import React from 'react';
import { Col, FlexboxGrid } from 'rsuite';

const Footer = ()=>{
    return(
        <FlexboxGrid justify="center" style={{marginTop:30, background:'#2ba19a', padding:20}}>
            <Col xs={24} sm={20} md={18}>
                <Col xs={24} sm={12} md={8}>
                    <div className="title-footer">
                        <div>
                            <span>Dirección</span>
                        </div>
                    </div>
                    <div className="content-footer">
                        <FlexboxGrid justify="center">
                            <Col xs={24} sm={22} md={18}>
                                <p>
                                    Calle de la libres 24<br/>
                                    Moctezuma<br/>
                                    San luis Potosí
                                </p>
                            </Col>
                        </FlexboxGrid>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} mdPush={8} >
                    <div className="title-footer">
                        <div>
                            <span>Contacto</span>
                        </div>
                    </div>
                    <div className="content-footer">
                        <FlexboxGrid justify="center">
                            <Col xs={24} sm={22} md={22}>
                                <p>
                                    <strong>Telefonos</strong><br/>                                
                                    4444195949<br/>
                                    4441427517
                                </p>
                                <p>contacto@concretosmarcan.com</p>
                            </Col>
                        </FlexboxGrid>
                    </div>
                </Col>
            </Col>
            
        </FlexboxGrid>
    )
}

export default Footer;