import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import Layout from './components/Layout';
import Routes from './Routes';
import './App.css';

class App extends Component{
	componentDidMount(){
		if(sessionStorage.getItem('token') === null){
			var token = '_' + Math.random().toString(36).substr(2, 9);
			sessionStorage.setItem('token', token);
		}
	}

	render(){
		const childProps = {};
		return(
			<Layout {...this.props}>
				<Routes {...this.props} childProps={childProps} />
			</Layout>
		)
	}	
}

export default withRouter(App);
