import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = (props)=>
    <div>
        <Header {...props} />
        
        {props.children}

        <Footer />
    </div>

export default Layout;