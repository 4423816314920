import React from 'react';
import { Grid, Col, FlexboxGrid, Divider, Panel } from 'rsuite';

const Service = (props)=>{
    return(
        <Grid fluid style={{padding:0}}>
            <div style={{padding:10}}>
                <h1 style={{textAlign:'center', color:'#49b9e6'}}>Servicios</h1>
            </div>
            <FlexboxGrid justify="center">
                <Col xs={24} sm={18} md={16}>
                    <Panel header="Bombeo de concreto premezclado">
                        <Grid fluid>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/premezcla/premezcla1.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/premezcla/premezcla2.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/premezcla/premezcla3.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/premezcla/premezcla4.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/premezcla/premezcla5.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/premezcla/premezcla6.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                        </Grid>
                    </Panel>
                    <Divider />
                    <Panel header="Laboratorio de concreto">
                        <Grid fluid>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/laboratorio/lab1.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/laboratorio/lab2.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/laboratorio/lab3.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                        </Grid>
                    </Panel>
                    <Divider />
                    <Panel header="Losas, Firmes y Rupturas">
                        <Grid fluid>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/losa/losa1.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/losa/losa2.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/losa/losa3.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/losa/losa4.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/losa/losa5.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Panel shaded >
                                    <img src="images/services/losa/losa6.jpeg" className="img-fluid" alt="" />
                                </Panel>
                            </Col>
                        </Grid>
                    </Panel>
                </Col>
            </FlexboxGrid>
            
        </Grid>
    )
}

export default Service;