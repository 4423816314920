import React from 'react';
import { Grid, FlexboxGrid, Col, Icon, Divider } from 'rsuite';
import ContactForm from '../components/ContactForm';

const Contact = (props)=>{
    return(
        <Grid fluid>
            <div style={{padding:10}}>
                <h1 style={{textAlign:'center', color:'#49b9e6'}}>Contacto</h1>
            </div>
            <FlexboxGrid justify="center">
                <Col xs={24} sm={24} md={22} lg={18}>
                    <Col xs={24} sm={7} md={8} lg={8}>
                        <div style={{textAlign:'center'}}>
                            <Icon icon="phone" size="lg" />
                        </div>
                        <FlexboxGrid justify="center">
                            <Col xs={24} sm={16} md={8} lg={8}>
                                <p>
                                    4444195949<br/>
                                    4441427517
                                </p>
                            </Col>                            
                        </FlexboxGrid>
                    </Col>
                    <Col xs={24} sm={10} md={8} lg={8}>
                        <div style={{textAlign:'center'}}>
                            <Icon icon="envelope-o" size="lg" />
                            <FlexboxGrid justify="center">
                                <Col xs={24}>
                                    <p>
                                        contacto@concretosmarsan.com
                                    </p>
                                </Col>                            
                            </FlexboxGrid>
                        </div>
                    </Col>
                    <Col xs={24} sm={7} md={8} lg={8}>
                        <div style={{textAlign:'center'}}>
                            <Icon icon="map-marker" size="lg" />
                            <FlexboxGrid justify="center">
                            <Col xs={24} sm={20} md={14} lg={12}>
                                <p style={{textAlign:'left'}}>
                                    Calle de la liebres 24<br/>
                                    Moctezuma<br/>
                                    San Luis Potosí
                                </p>
                            </Col>                            
                        </FlexboxGrid>
                        </div>
                    </Col>
                </Col>
            </FlexboxGrid>
            
            <FlexboxGrid justify="center">
                <Col xs={22} sm={20}>
                <Divider />
                    <Col xs={24} sm={12}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.6292987029706!2d-101.0803085850511!3d22.742015885095846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86804471218ba573%3A0x931ffcfacd10d316!2sC.%20de%20las%20Liebres%2024%2C%20Col%20Las%20Lomas%2C%2078900%20Moctezuma%2C%20S.L.P.!5e0!3m2!1ses-419!2smx!4v1630336781137!5m2!1ses-419!2smx" 
                            width="100%" height="450" style={{border:0}} allowFullScreen loading="lazy" title="map"></iframe>
                    </Col>
                    <Col xs={24} sm={12}>
                        <ContactForm {...props} />
                    </Col>
                </Col>
            </FlexboxGrid>
        </Grid>
    )
}

export default Contact;