import React from 'react';
import Slider from '../components/CallToAction';
import { FlexboxGrid, Col, Grid, Panel, Divider } from 'rsuite';

const Home = (props)=>
    <div>
        <Slider {...props} />
        
        <div style={{background:'#2ba19a', textAlign:'center', padding:8, color:'#fff'}}>
            <h2>Cubicación, Resistencia, Aplicaciones y Capacitación</h2>
        </div>

        <FlexboxGrid justify="center" style={{marginTop:10, marginBottom:30}} className="form-group">
            <Col lg={18}>
                <h1 style={{textAlign:'center', color:'#49b9e6'}}>Servicios</h1>
                <Grid fluid>
                    <Col xs={24} sm={12} md={6}>
                        <Panel shaded className="service">
                            <img src="images/services/premezcla.jpeg" className="img-fluid" alt="" />                            <Panel className="title-service" header="Bombeo de Concreto Premezclado"></Panel>
                        </Panel>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Panel shaded className="service">
                            <img src="images/services/laboratorio.jpeg" className="img-fluid" alt="" />                            <Panel className="title-service" header="Laboratorio de Concreto"></Panel>
                        </Panel>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Panel shaded className="service">
                            <img src="images/services/modelo.jpeg" className="img-fluid" alt="" />                            <Panel className="title-service" header="Modelo de Ruptura"></Panel>
                        </Panel>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Panel shaded className="service">
                            <img src="images/services/losa.jpeg" className="img-fluid" alt="" />                            <Panel className="title-service" header="Losas, Firmes y Rupturas"></Panel>
                        </Panel>
                    </Col>
                </Grid>
            </Col>
        </FlexboxGrid>

        <FlexboxGrid justify="center" style={{background:'#383f3f'}}>
            <Col lg={18} style={{padding:20}}>
                <h1 style={{textAlign:'center', fontWeight:'bold', color:'#fff'}}>¿Por qué nosotros?</h1>
                <FlexboxGrid justify="center">
                    <Col xs={22} sm={20} md={2}>
                        <Divider />
                    </Col>
                </FlexboxGrid>
                <FlexboxGrid justify="center">
                    <Col xs={24} lg={14}>
                        <div style={{color:'#fff', textAlign:'justify'}} >
                            Contamos con personal altamente capacitado y calificado en todas las áreas del mismo, tales como:
                        
                            <ul>
                                <li>Estructuras</li>
                                <li>Pavimentos</li>
                                <li>Pisos</li>
                                <li>Concretos autocompactables para molde</li>
                                <li>Prefabricados</li>
                            </ul>
                        </div>
                    </Col>
                </FlexboxGrid>
            </Col>
        </FlexboxGrid>

        <FlexboxGrid justify="center" style={{marginTop:10, marginBottom:30}} className="form-group">
            <Col lg={18}>
                <h1 style={{textAlign:'center', color:'#49b9e6'}}>Proyectos</h1>
                <Grid fluid>
                    <Col xs={24} sm={12} md={8}>
                        <Panel shaded className="projects">
                            <img src="images/projects/1.jpeg" className="img-fluid" alt="" />
                        </Panel>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Panel shaded className="projects">
                            <img src="images/projects/2.jpeg" className="img-fluid" alt="" />
                        </Panel>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Panel shaded className="projects">
                            <img src="images/projects/3.jpeg" className="img-fluid" alt="" />
                        </Panel>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Panel shaded className="projects">
                            <img src="images/projects/4.jpeg" className="img-fluid" alt="" />
                        </Panel>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Panel shaded className="projects">
                            <img src="images/projects/5.jpeg" className="img-fluid" alt="" />
                        </Panel>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Panel shaded className="projects">
                            <img src="images/projects/6.jpeg" className="img-fluid" alt="" />
                        </Panel>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Panel shaded className="projects">
                            <img src="images/projects/7.jpeg" className="img-fluid" alt="" />
                        </Panel>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Panel shaded className="projects">
                            <img src="images/projects/8.jpeg" className="img-fluid" alt="" />
                        </Panel>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Panel shaded className="projects">
                            <img src="images/projects/9.jpeg" className="img-fluid" alt="" />
                        </Panel>
                    </Col>
                </Grid>
            </Col>
        </FlexboxGrid>
    </div>
    

export default Home;