import React, { Component } from 'react';
import { FlexboxGrid, Col, Button } from 'rsuite';
import 'animate.css';

import $ from 'jquery';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import '../assets/carousel/carousel.css';
let interval;

class CallToAction extends Component{

    componentDidMount(){
        interval = setInterval(()=>{
            if($('.current').children('.welcome').length > 0){
                $('.welcome').addClass('animate__animated animate__pulse');
            }else{
                $('.welcome').removeClass('animate__animated animate__pulse');
            }
    
            if($('.current').children('.about').length > 0){
                $('.about').addClass('animate__animated animate__fadeInLeft');
                $('.about').css('display', '');
            }else{
                $('.about').removeClass('animate__animated animate__fadeInLeft');
                $('.about').css('display', 'none');
            }
    
            if($('.current').children('.contact').length > 0){
                $('.contact').addClass('animate__animated animate__fadeInDown');
                $('.contact').css('display', '');
            }else{
                $('.contact').removeClass('animate__animated animate__fadeInDown');
                $('.contact').css('display', 'none');
            }
        }, 1000);
    }
    componentWillUnmount(){
        clearInterval(interval);
    }
   
    render(){
        let content = [
            {
                image:'images/slider/bienvenido.jpg',
                info:<FlexboxGrid 
                        justify="center" 
                        className="welcome"
                        style={{position:'absolute', top:'25%', width:$(window).width(), textAlign:'center'}}
                    >
                    <Col xs={24} sm={12} md={12} lg={12} style={{background:'rgba(0,0,0,0.39)', color:'#FFF'}}>
                        <div>
                            <span style={{fontSize:70}}>MARSAN</span>
                        </div>
                        <span style={{fontSize:25}}>Infraestructura y Concretos</span>
                    </Col>
                    
                </FlexboxGrid>
            },
            {
                image:'images/slider/nosotros.jpeg',
                info:<FlexboxGrid 
                    className="about"
                    justify="start" 
                    style={{position:'absolute', bottom:'10%', width:$(window).width(), display:'none'}}>
                    <Col xs={24} sm={22} md={12} style={{color:'#FFF', padding:10}}>
                        <div style={{padding:10}}>
                            <span style={{fontSize:35, fontWeight:'bold'}}>Servicio, Calidad y Confianza</span>
                        </div>
                        <div style={{paddingLeft:20, paddingTop:10}}>
                            <Button style={{background:'rgb(43, 161, 154)', color:'#FFF', fontWeight:'bold'}}
                                 onClick={()=>this.props.history.push('/servicios')}
                            >
                                Servicios
                            </Button>
                        </div>
                    </Col>
                </FlexboxGrid>
            },
            { 
                image:'images/slider/contacto.jpg',
                info:  <FlexboxGrid 
                        className="contact"
                        justify="end"
                        style={{position:'absolute', top:'20%', width:$(window).width()-15, display:'none'}}
                    >
                        <Col xs={21} sm={14} md={8} style={{color:'#fff', padding:10}}>
                            <div style={{fontSize:30, fontWeight:'bold'}}>Bombeo de Concreto Premezclado</div>
                            <div style={{fontSize:30, fontWeight:'bold'}}>Laboratorio de Concreto</div>
                            <div style={{paddingLeft:20, paddingTop:10, textAlign:'center'}}>
                                <Button 
                                    style={{background:'rgb(43, 161, 154)', color:'#fff', fontWeight:'bold'}}
                                    onClick={()=>this.props.history.push('/contacto')}
                                >
                                    Contacto
                                </Button>
                            </div>
                        </Col>
                        
                    </FlexboxGrid>
            }
        ];

        return (
            <Slider >
                {content.map((item, index) => (
                    <div
                        key={index}
                        style={{ background: `url('${item.image}') no-repeat `+(index !== 1 ? 'center center' : '') }}
                    >
                        {item.info}
                    </div>
                ))}
            </Slider>
        );
    }
}
    
export default CallToAction;